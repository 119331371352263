@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/**
*** SIMPLE GRID
*** (C) ZACH COLE 2016
**/

/* UNIVERSAL */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

/* ROOT FONT STYLES */

* {
  font-family: 'Lato', Helvetica, sans-serif;
  color: #333447;
  line-height: 1.5;
}

/* TYPOGRAPHY */

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

/* ==== GRID SYSTEM ==== */

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}

.col-1-sm {
  width: 4.33%;
}

.col-2-sm {
  width: 12.66%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33%;
}

.col-5-sm {
  width: 37.66%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33%;
}

.col-8-sm {
  width: 62.66%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33%;
}

.col-11-sm {
  width: 87.66%;
}

.col-12-sm {
  width: 96%;
}

.row::after {
	content: "";
	display: table;
	clear: both;
}

.hidden-sm {
  display: none;
}

@media only screen and (min-width: 33.75em) {  /* 540px */
  .container {
    width: 80%;
  }
}

@media only screen and (min-width: 45em) {  /* 720px */
  .col-1 {
    width: 4.33%;
  }

  .col-2 {
    width: 12.66%;
  }

  .col-3 {
    width: 21%;
  }

  .col-4 {
    width: 29.33%;
  }

  .col-5 {
    width: 37.66%;
  }

  .col-6 {
    width: 46%;
  }

  .col-7 {
    width: 54.33%;
  }

  .col-8 {
    width: 62.66%;
  }

  .col-9 {
    width: 71%;
  }

  .col-10 {
    width: 79.33%;
  }

  .col-11 {
    width: 87.66%;
  }

  .col-12 {
    width: 96%;
  }

  .hidden-sm {
    display: block;
  }
}

@media only screen and (min-width: 60em) { /* 960px */
  .container {
    width: 75%;
    max-width: 60rem;
  }
}

.App {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  color: #2d3436;
}

.App-header {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-start;
  font-size: calc(10px + 0.8vmin);
  font-weight: 400;
  margin-top: 15px;
}

.App-header .container {
  position: fixed;
  right: 0px;
  left: 0px;
}

.App-main {
  margin: 20vh auto 0 !important;
  font-size: calc(10px + 2vmin);
}

.intro p {
  font-size: calc(10px + 1.1vmin);
  margin-bottom: 30px;
}

.intro h1 {
  font-size: calc(10px + 5.1vmin);
  font-weight: 600;
  margin-bottom: 20px;
}

.intro h2 {
  font-size: calc(10px + 2.5vmin);
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 10px;
}

h2 a{
  text-decoration: none;
}

.App-header li {
  display: inline;
  margin-left: 20px;
}

.App-header li a {
  text-decoration: none;
}

.projects {
  margin-top: 20px;
}

a.anchor--highlighted {
  border-bottom: .2em solid #b2ebf2;
  box-shadow: inset 0 -5px 0 #b2ebf2;
  -webkit-transition: background .15s cubic-bezier(.33,.66,.66,1);
  transition: background .15s cubic-bezier(.33,.66,.66,1);
}
a.anchor {
  text-decoration: none;
  color: #000;
}

#resume a {
  text-decoration: none;
  font-size: calc(10px + 0.8vmin);
  border-bottom: .2em solid #b2ebf2;
  box-shadow: inset 0 -5px 0 #b2ebf2;
  -webkit-transition: background .15s cubic-bezier(.33,.66,.66,1);
  transition: background .15s cubic-bezier(.33,.66,.66,1);
}

/* a.anchor--highlighted:hover{
  background-color: #b2ebf2;
} */

#projectList img {
  border: 1px solid #dfe6e9;
  object-fit: cover;
  width: 93%;
  padding: 5px;
  margin-bottom: 30px;
}

hr.col-12 {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 35px !important;
}

#stack img {
  object-fit: cover;
  width: 70px;
  margin-bottom: 30px;
}

#contacts img {
  object-fit: cover;
  width: 30px;
  margin-bottom: 30px;
}

h2.col-12.section-heading {
  margin-bottom: 20px;
}

h2 {
  font-size: calc(10px + 4vmin);
  font-weight: 300;
}

.strong {
  font-weight: 400;
}

div.col-8.description {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: calc(10px + 0.8vmin);
  font-weight: 300;
  line-height: 1.7;
}

#contacts .col-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: end;
}

#stack .col-12.stacklogos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: start;
}

.clear:before,
.clear:after {
    content: " ";
    display: table;
}

.clear:after {
    clear: both;
}

@media screen and (max-width: 450px) {
  #stack img {
    width: 35px;
  }
  
  .intro p {
  font-size: calc(10px + 1.5vmin);
  margin-bottom: 30px;
}

div.col-8.description {
  font-size: calc(10px + 1.5vmin);
}

#resume a {
  font-size: calc(10px + 1.5vmin);
}
}
