.App {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  color: #2d3436;
}

.App-header {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-start;
  font-size: calc(10px + 0.8vmin);
  font-weight: 400;
  margin-top: 15px;
}

.App-header .container {
  position: fixed;
  right: 0px;
  left: 0px;
}

.App-main {
  margin: 20vh auto 0 !important;
  font-size: calc(10px + 2vmin);
}

.intro p {
  font-size: calc(10px + 1.1vmin);
  margin-bottom: 30px;
}

.intro h1 {
  font-size: calc(10px + 5.1vmin);
  font-weight: 600;
  margin-bottom: 20px;
}

.intro h2 {
  font-size: calc(10px + 2.5vmin);
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 10px;
}

h2 a{
  text-decoration: none;
}

.App-header li {
  display: inline;
  margin-left: 20px;
}

.App-header li a {
  text-decoration: none;
}

.projects {
  margin-top: 20px;
}

a.anchor--highlighted {
  border-bottom: .2em solid #b2ebf2;
  box-shadow: inset 0 -5px 0 #b2ebf2;
  transition: background .15s cubic-bezier(.33,.66,.66,1);
}
a.anchor {
  text-decoration: none;
  color: #000;
}

#resume a {
  text-decoration: none;
  font-size: calc(10px + 0.8vmin);
  border-bottom: .2em solid #b2ebf2;
  box-shadow: inset 0 -5px 0 #b2ebf2;
  transition: background .15s cubic-bezier(.33,.66,.66,1);
}

/* a.anchor--highlighted:hover{
  background-color: #b2ebf2;
} */

#projectList img {
  border: 1px solid #dfe6e9;
  object-fit: cover;
  width: 93%;
  padding: 5px;
  margin-bottom: 30px;
}

hr.col-12 {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 35px !important;
}

#stack img {
  object-fit: cover;
  width: 70px;
  margin-bottom: 30px;
}

#contacts img {
  object-fit: cover;
  width: 30px;
  margin-bottom: 30px;
}

h2.col-12.section-heading {
  margin-bottom: 20px;
}

h2 {
  font-size: calc(10px + 4vmin);
  font-weight: 300;
}

.strong {
  font-weight: 400;
}

div.col-8.description {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: calc(10px + 0.8vmin);
  font-weight: 300;
  line-height: 1.7;
}

#contacts .col-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: end;
}

#stack .col-12.stacklogos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: start;
}

.clear:before,
.clear:after {
    content: " ";
    display: table;
}

.clear:after {
    clear: both;
}

@media screen and (max-width: 450px) {
  #stack img {
    width: 35px;
  }
  
  .intro p {
  font-size: calc(10px + 1.5vmin);
  margin-bottom: 30px;
}

div.col-8.description {
  font-size: calc(10px + 1.5vmin);
}

#resume a {
  font-size: calc(10px + 1.5vmin);
}
}